import Offshore from '@/components/OffShore';

export default {
  home: {
    btnmore: '続き読むを',
    title_pc:
      '高品質のオフショアサービスを高コストパフォーマンスでご提供します。',
    title_mb:
      '高品質のオフショアサービスを高コストパフォーマンスでご提供します。',
    title_sub: '魅力的なコストで開発を実現',
    btn_readmore: '続きを読む',
    news_hot: '特集ニュース',
    service_provide: '私たちが提供するサービス',
    service_provide_sub:
      '強力なソフトウェア ソリューションを提供します。世界中の顧客にとって拡張性と信頼性に優れています。',
    reason: 'TCOM JAPANを選ぶ理由',
    reason_sub1: '魅力的な料金体系で60%の</br>コスト削減',
    reason_sub2: '日本人の品質管理担当も在籍',
    reason_sub3:
      '<p>優秀なエンジニアチーム,</br>プロフェッショナルによる</br>プロセス</p>',
    method_cooperation: 'TCOM JAPANの契約形態',
    method_cooperation_sub: '各要件に合わせて柔軟にご支援方法を提案致します',
    project_based_title: '請負型',
    project_based_description:
      '請負型とはプロジェクトごとに契約が締結される、発注元の依頼に従い成果物を作成し、納品します。',
    labor_title: 'ラボ型',
    labor_description:
      'ラボ型オフショア開発とは一定期間プロジェクトに必要な技術を持ったエンジニアを確保して専属の開発チーム提供します。',
    step_active: '契約までの流れ',
    step_active_description:
      '開発プロセスは簡潔でわかりやすく、連携しやすいのが特徴です。',
    step1: 'ご相談・ヒアリング',
    step1_des: 'ヒアリング、相談、調査、要件の明確化',
    step2: 'NDA',
    step2_des: 'アーキテクチャ設計、インターフェースのスケッチ、UI/UX設計',
    step3: 'ご提案・御見積',
    step3_des: 'プログラミングの作成',
    step4: '契約締結',
    step4_des: 'プログラミングの作成',
    step5: '業務開始',
    step5_des: '検証、ベータ版、本番環境へ移行',
    step6: '納品',
    step6_des: '品質評価、パフォーマンス',
    tech_language: '技術・言語',
    tech_language_description:
      'お客様により良い製品をご提供するために、当社は常に社員の能力開発に努めています。',
    feature_project: '傑出したプロジェクト',
    feature_project_des:
      '私たちが成功裏に実装し、顧客を満足させたいくつかの優れたプロジェクト',
    achievement: '実績',
    achievement_feature: '実績',
    achievement_feature_des:
      'TCOM は国内外企業の信頼できるパートナー企業として、これまでも、これからも、規模の拡大、チームレベルの向上、厳格な品質管理体制を追求していきます。        ',
    project: '計画',
    customer: 'クライアント',
    engineer: 'エンジニア',
    expert: '専門家 ',
    job: 'リクルート',
    job_des: 'TCOMでのキャリア開発の機会',
    image: '写真',
    office: 'オフィス写真',
    office1: 'TCOM',
    office2: 'オフィス',
    news: 'ニュース',
    news_des: 'TCOM JAPANの最新ニュース、展望、技術イベン',
    start: 'ビデオを見る',
  },
  layout: {
    about: '会社概要',
    service: 'サービス',
    solution: 'ソリューション',
    project: '開発実績',
    job: '採用情報',
    offshore: 'オフショアサービス',
    news: 'ニュース',
    contact: 'お問い合わせ',
    home: 'ホームページ',
    introduce: '導入',
    terms_of_service: '利用規約',
    Privacy_Policy: 'プライバシーポリシー',
    contact_description:
      'Hãy gửi cho chúng tôi những thắc mắc, góp ý hoặc đề nghị hợp tác của bạn.Chúng tôi sẽ phản hồi trong thời gian sớm nhất!',
    contact_submit: 'GỬI LIÊN HỆ HỢP TÁC',
    footer_title: 'TCOM VIỆT NAM',
    footer_service: 'CÁC DỊCH VỤ',
    footer_title_tcomjp: 'TCOM JAPAN',
    footer_title_keep: '連絡を取り合う',
    footer_title_menu: 'メニュー',
    footer_title_tcomvn: 'TCOM',
    back: '戻る',
    office_hanoi: '本部 (ハノイ)',
    office_hcm: '開発拠点（ホーチミン市）',
    email_tcomjp: 'sales@tcom-japan.com',
  },
  service: {
    our_service_sub: 'サービス内容',
    our_service_sub1: 'サービス',
    our_service_sub2: '私たちのもの',
    strength_sub1: '強み',
    strength_sub2: '私たちのもの',
    language_code: 'プログラミング言語',
    tech: 'テクノロジー',
    step_active: '実施じっしプロセス',
    step_active_description:
      'やり取りから作業さぎょうまでのステップがシンプルで分かりわかりやすく連携れんけいしやすい',
    field: '分野',
    field_description: 'サービス提供エリア',
  },
  project: {
    feature_project: '注目のプロジェクト',
    feature_project_description:
      '強力なソフトウェアソリューションを提供し、世界中のお客様にとってスケーラブルで信頼性の高いものです。',
    project_sub1: 'Một số dự án nổi bật mà chúng tôi đã triển khai thành công,',
    project_sub2: 'làm khách hàng hài lòng.',
    field: '分野',
    language: '言語',
    no_result: '検索結果がありません',
    back_home: 'ホームページ へ戻る',
    load_more: '続きを読む',
    tech: 'テクノロジー',
    scale: '規模',
    platform: '財団',
    search: '探す',
  },
  news: {
    title: 'Tin tức',
    feature_news: '特集ニュース',
    feature_news_des: '<p></p>',
  },
  about: {
    title: 'TCOM JAPAN',
    desc: 'OFFSHOREサービス提供',
    about_us: 'TCOM JAPAN について',
    about_us_text1:
      '日本市場向けのオフショアサービスおよびITコンサルティングを専門として、2021年5月に東京本社を設立。親会社であるベトナムのTCOM社は、優秀なエンジニアチームを擁し、常に革新的かつ専門的なソフトウェアの開発を実現。厳格な品質管理にも定評のある、多領域のテクノロジー企業です。',
    about_us_text2:
      'TCOM JAPANは、これまでも、これからも、主要顧客である日本企業のパートナーであり続けます。',
    profile_title: '会社概要',
    tcom_japan: 'TCOM JAPAN',
    tcom_vietnam: 'TCOM VIET NAM',
    some_pics: 'いくつかの写真',
    us_title: 'コアメンバーご紹介',
    profile_text1:
      'オフショアサービス、ITシステムコンサルティング、ソフトウェア開発を提供しています。 当社が得意とする領域は、AI、ブロックチェーン、AR/VR、動画ライブストリーミングです。',
    download: '今すぐダウンロード',
    achievement_title: '実績',
    achievement_text1:
      'TCOM は国内外企業の信頼できるパートナー企業として、これまでも、これからも、規模の拡大、チームレベルの向上、厳格な品質管理体制を追求していきます。',
    customer_title: '主な取引先',
    tabs_vn: 'VIET NAM',
    tabs_jp: 'JAPAN',

    title_sub1:
      '<h1>Chúng tôi là TCOM</br> Công ty công nghệ đa lĩnh vực,<br> đối tác IT tin cậy của bạn</h1>',
    history_mount: 'Lịch sử hình thành',
    history_description:
      ' <p>TCOM là công ty công nghệ, thành lập năm 2012, bởi đội ngũ kỹ sư IT có hơn 15 năm kinh nghiệm phát triển sản phẩm, cũng như thực hiện các dự án công nghệ với các đối tác trong và ngoài nước.</br> TCOM quy tụ những con người tài năng, nhiệt huyết, đam mê, sáng tạo, tốt nghiệp từ những trường đại học hàng đầu Việt Nam</p>',
    mission: 'Sứ mệnh',
    mission_description:
      '<p>Tạo ra các sản phẩm và giải pháp công nghệ chất lượng, thúc đẩy quá trình chuyển đổi số toàn diện của xã hội </p>',
    vision: 'Tầm nhìn',
    vision_description:
      'Trở thành tập đoàn công nghệ đa lĩnh vực hàng đầu Việt Nam',
    roadmap_development: 'Lộ trình phát triển',
    staff: 'Nhân sự',
    staffplus: 'Hướng tới quy mô 100+ nhân sự',
    staff2021: 'nhân sự',
    staff2017: 'Nhân sự',
    debut: 'Ra mắt',
    Outsourcing: 'Mở mảng Outsourcing',
    skyStudioSkyMitSkyBot: 'Sky Studio, Sky Mit, Sky Bot',
    jun_2017: '06/2017',
    establish: 'Thành lập công ty con',
    establish_description:
      'TCOM Software, outsourcing service trong và ngoài nước',
    rice_wallet: 'Ra mắt Rice Wallet',
    rice_wallet_description: 'Ví quản lý tiền điện tử phi tập trung',
    Tcom_Japan: 'TCOMJAPAN',
    japan_outsource: 'Outsourcing service tại Nhật Bản',
    eyefire_description: 'Sản phẩm và giải pháp trí tuệ nhân tạo',
    japan_singapo: 'Tiếp cận thị trường nước ngoài Nhật Bản, Singapore',
    progress_end: 'Đẩy mạnh nghiên cứu công nghệ, hoàn thiện quy trình',
    japan_singapo_2018: 'Thị trường Nhật Bản, Singapore',
    company_member: 'HỆ THỐNG THÀNH VIÊN CỦA CHÚNG TÔI',
    achievements: 'THÀNH TÍCH ĐẠT ĐƯỢC',
    achievement_description:
      'Luôn mở rộng quy mô, nâng cao trình độ đội ngũ, quy trình kiểm soát chất lượng nghiêm ngặt, TCOM đã và đang là đối tác tin cậy của các doanh nghiệp trong và ngoài nước',
    customer: 'Khách hàng',
    experts: 'Chuyên gia',
    subTitle: `<p class='desc'>Tcom không ngừng lớn mạnh, chúng tôi đem đến cho bạn những giải pháp,<br/> công nghệ tiện ích cuộc sống trong thời đại 4.0</p>`,
    feedback: 'Nhận xét của khách hàng',
    customer_uppercase: 'KHÁCH HÀNG',
    customer_uppercase_description: `<p className='desc'>Chúng tôi có những khách hàng từ khắp nới trên thế giới với sự tận tâm, trách nhiệm,<br /> sáng tạo chúng tôi đem đến đối tác những dịch vụ sản phẩm chất lượng và sự hài lòng cao nhất.</p>`,
    image: 'Hình ảnh',
    office: 'Văn Phòng TCOM',
    start: 'Bắt đầu',
    established: 'Thành lập',
    research:
      'Nghiên cứu công nghệ, phát triển sản phẩm LiveStreaming, WebRTC, AI, ML',
    menu_2021:
      'Nâng cao trình độ đội ngũ, nâng cấp quy trình quản lý chất lượng',
  },
  contact: {
    contact_title: 'お問い合わせ',
    contact_text1: 'お問い合わせはメール・お電話にてお受けしております。',
    contact_text2:
      'お送りいただいた内容を確認のうえ、ご返答させていただきます。',
    tcom_japan: 'TCOM JAPAN',
    keep_in_touch: '連絡を取り合う',
    name: 'フルネーム',
    btn_submit: '情報を送信',

    title: 'Để lại thông tin liên hệ',
    description: ` <p className='desc'>Gửi thông tin để được tư vấn, báo giá, hoặc liên hệ hợp tác kinh doanh.<br /> Vui lòng gửi email cho chúng tôi bất cứ lúc nào.<br /> Chúng tôi sẵn sàng lắng nghe và hỗ trợ quý khách.</p>`,
    tcom_vn: 'TCOM Việt Nam',
    phone: 'Số điện thoại',
    tieu_de: 'Tiêu đề',
    purpose: 'Mục đích',
    content: 'Nội dung',
    submit: 'GỬI THÔNG TIN',
    member: 'CÔNG TY THÀNH VIÊN',
  },
  solution: {
    solution_title: '優れたソリューション',
    solution_text1:
      '当社は、多くのプラットフォーム開発を手掛ける包括的な技術ソリューションにより、企業や組織',
    solution_text2:
      'のデジタルトランスフォーメーション（DX）を推進しています。',
    livestream_title: '双方向型ライブストリームソリューション',
    livestream_text:
      'ユーザーがライブストリーム配信において、ゲストをライブに招待できるほか、フレームやステッカーを追加したり、画像や色を編集したりできるアプリケーションを開発します。視聴者は、ライブチャットやライブストリームに参加してギフトやポイントを贈ることができます。',
    btnxt: '続きを読む',
    livestream_sell_title: 'ライブコマース向けのソリューション',
    livestream_sell_text:
      'ライブコマース向けのソリューションライブストリームを通じたオンラインショッピングシステムを開発します。売り手は倉庫から販売商品を紹介しながら、視聴者と直接対話し、注文を受けることができます。',
    livestream_travel_title: 'オンライン旅行向けのソリューション',
    livestream_travel_text:
      'ユーザーがライブストリーム配信において、ゲストをライブに招待できるほか、フレームやステッカーを追加したり、画像や色を編集したりできるアプリケーションを開発します。視聴者は、ライブチャットやライブストリームに参加してギフトやポイントを贈ることができます。',
    VR_title: '360度VR画像デジタル化ソリューション',
    VR_text:
      '360度VR画像によるオンライン観光のプラットフォーム、VR見本市、展示プラットフォームを開発します。',
    smart_school_title: '学校向けのソリューション',
    smart_school_text:
      '学生管理の質を向上させることを目的に、顔認証技術に基づく学生の識別、出欠管理、監督等に関する学校向けのソリューションを開発しています。 同時に透明性も確保します。',
    smart_building_title: 'ビル向けのソリューション',
    smart_building_text:
      'ビル向けのソリューションを開発。顔認証技術により居住者の自動識別、ドアの開閉、サービスの利用などを可能にします。 また、居住者がゲストのためにドアをリモートで開錠することもできます。',
    cloud_cam_title: 'クラウド型カメラ向けのソリューション',
    cloud_cam_text:
      '人工知能に基づいた画像表示・分析を可能にする、中央集中型のストレージおよび管理ソリューションであるクラウド型プラットフォームを開発しています。',
    onl_edu_title: 'オンライン教育向けのソリューション',
    onl_edu_text:
      '映像を活用したオンライン教育、リアルタイムの双方向な授業などを実現する学習システムを開発。',
    tele_title: '遠隔医療向けのソリューション',
    tele_text:
      '遠隔診療・健康相談アプリを開発し、医師と患者のマッチングを実現。 ビデオ通話やインスタントメッセージによる相談が可能です。',

    feature_solution: 'Các giải pháp nổi bật',
    feature_solution_description: `<p className='desc'>TCOM đã và đang phát triển nhiều nền tảng, giải pháp công nghệ toàn diện,<br /> thúc đẩy quá trình chuyển đổi số của doanh nghiệp, tổ chức.</p>`,
    livestream_interactive: `<div className='name'>Giải pháp Livestream<br /> tương tác</div>`,
    livestream_sale: `<div className='name'>Giải pháp Livestream<br /> bán hàng</div>`,
    service_travel: ` <div className='name'>Giải pháp dịch vụ<br /> du lịch</div>`,
    digital: ` <div className='name'>Giải pháp số hóa<br /> VR 360</div>`,
    school_smart: ` <div className='name'>Giải pháp trường học<br /> thông minh</div>`,
    camera: ` <div className='name'>Giải pháp<br /> Cloud Camera</div>`,
    building_smart: `  <div className='name'>Giải pháp<br /> tòa nhà thông minh</div>`,
    education: `<div className='name'>Giải pháp<br /> Online Education</div>`,
    telehealth: `<div className='name'>Giải pháp<br /> Telehealth</div>`,

    solution_1: `<h5>Giải pháp Livestream<br /> tương tác</h5>`,
    solution_1_description:
      'Phát triển các ứng dụng cho phép người dùng livestream, mời khách cùng live, thêm khung hình, sticker, hiệu chỉnh hình ảnh, màu sắc. Người xem có thể chat trực tiếp, xin tham gia cùng livestream, tặng quà, tặng điểm.',
    solution_2: `<h5>Giải pháp Livestream<br /> bán hàng</h5>`,
    solution_2_description:
      'Phát triển hệ thống online-shopping thông qua hình thức livestream, người bán livestream giới thiệu sản phẩm từ kho hàng, người xem tương tác, xem sản phẩm, và đặt hàng trực tiếp.',
    solution_3: `<h5>Giải pháp du lịch<br /> trực tuyến</h5>`,
    solution_3_description:
      'Phát triển các ứng dụng cho phép người dùng livestream, mời khách cùng live, thêm khung hình, sticker, hiệu chỉnh hình ảnh, màu sắc. Người xem có thể chat trực tiếp, xin tham gia cùng livestream, tặng quà, tặng điểm.',
    solution_4: `<h5>Giải pháp số hóa hình ảnh<br /> 360 VR</h5>`,
    solution_4_description:
      'Phát triển nền tảng du lịch trực tuyến qua hình ảnh 360 VR, nền tảng hội chợ và triển lãm thực tế ảo',
    solution_5: `<h5>Giải pháp trường học<br /> thông minh</h5>`,
    solution_5_description:
      'Phát triển giải pháp trường học thông minh, tập trung vào việc nâng cao chất lượng quản lý học sinh thông qua nhận diện, điểm danh, giám sát dựa trên công nghệ nhận diện khuôn mặt. Đồng thời đảm bảo sự thông suốt',
    solution_6: `<h5>Giải pháp tòa nhà<br /> thông minh</h5>`,
    solution_6_description:
      'Phát triển giải pháp toà nhà thông minh, cho phép tự động nhận diện cư dân thông qua khuôn mặt, để mở cửa, để sử dụng dịch vụ. Đồng thời cho phép cư dân có thể mở cửa từ xa cho khách của mình.',
    solution_7: `<h5>Giải pháp<br /> Cloud Camera</h5>`,
    solution_7_description:
      'Phát triển giải pháp quản lý và lưu trữ camera tập trung, nền tảng cloud, cho phép xem và phân tích hình ảnh dựa trên công nghệ trí tuệ nhân tạo.',
    solution_8: `<h5>Giải pháp<br /> Online Education</h5>`,
    solution_8_description:
      'Phát triển các hệ thống học, dạy học trực tuyến thông qua video, thông qua lớp học tương tác thời gian thực',
    solution_9: `<h5>Giải pháp<br /> Telehealth</h5>`,
    solution_9_description:
      'Phát triển Ứng dụng khám, tư vấn sức khoẻ từ xa theo yêu cầu, cho phép matching bác sĩ và bệnh nhân. Tư vấn thông qua video call, instant message.',
  },
  text_button: {
    read_more: '続きを読む',
    keep_in_touch: 'LIÊN HỆ',
  },
  off_shore: {
    title: 'オフショアサービス',
    title_dis: 'TCOM JAPAN',
    introduce:
      'オフショアとは一般的に、コスト削減を目的として、人件費や物価の安い海外企業に業務の一部を委託することです。',
    benefit: 'オフショアのメリットは?',
    benefit_description:
      '内部リソースでは問題解決ができない→人員や技術面で、実施・処理に時間を要する問題はオフショア移行で解決します。コスト削減をしたい→オフショアは、自社ですべて手がける場合、または自国内で人員を雇用する場合に比べて、大幅にコストを削減できます。コア業務に集中したい→優先順位の低い仕事をオフショアし、価値を生み出すコア業務に自社の十分なリソースを投入することが可能になります。スピードを重視したい→多くのスタートアップ企業は、迅速かつ安価なサービスを求めてオフショアに移行しています。',
    slogan1:
      '高品質・高コストパフォーマンスのオフショアサービスを提供しています',
    //  slogan2:'アサービスを提供しています',
    slogan_des_1: '高いコストパフォーマンス',
    slogan_des_3: '高品質',
    slogan_des_5: '即時対応',
    achievement: '業績',
    achievement1: '計画',
    achievement2: 'クライアント',
    achievement3: 'エンジニア',
    achievement4: '専門家',
    company: '会社',
    service: '私たちが提供するサービス',
    reason: 'TCOM JAPANを選ぶ理由',
    reason_1: '魅力的な料金体系で60%のコスト削減',
    reason_2: '日本人の品質管理担当も在籍',
    reason_3: '優秀なエンジニアチームプロフェッショナルによるプロセス',
    method: '契約形態',
    method_1: 'プロジェクトベース（請負型)',
    method_2: '専属チームの編成（ラボ型)',
    method_des_1:
      'プロジェクトごとに契約を結ぶオフショア形態です。当社が、顧客から要望をうかがい、要件定義、設計、開発、テスト、リリースなど、プロジェクトの開発工程におけるすべての活動および開発チームを管理します。',
    method_des_2:
      '当社内に専属の開発チームを編成し、管理、運用することができます。プロジェクト遂行のための安定したマンパワーを確保します。',
    pattern_1: 'パターン①',
    pattern_2: 'パターン②',
    pattern_des_1: '顧客とブリッジSE（BrSE） が直接連携して作業を行います。',
    pattern_des_2:
      'ブリッジSEが顧客側に出向き、ベトナム側に専属の開発担当チームを立ち上げます。',
    service_1: 'ウェブ開発',
    service_2: 'モバイルアプリ開発',
    service_3: 'ビデオソリューション',
    service_4: 'リアルタイムソリューション',
    service_5: '人工知能（AI）ソリューション研究開発',
    service_6: 'ブロックチェーン開発',
    service_7: 'QAテスト',
    service_8: 'IT保守・管理',
    service_9: 'UI/UXデザイン',
    procedure: '開発プロセス',
    procedure_des:
      '開発プロセスは簡潔でわかりやすく、連携しやすいのが特徴です。',
    procedure_1: '情報収集',
    procedure_2: '設計',
    procedure_3: 'プログラミング',
    procedure_4: '検証',
    procedure_5: 'リリース',
    procedure_6: '評価',
    procedure_des_1: 'ヒアリング、相談、調査、要件の明確化',
    procedure_des_2:
      'アーキテクチャ設計、インターフェースのスケッチ、UI/UX設計',
    procedure_des_3: 'プログラミングの作成',
    procedure_des_4: 'プログラミングの作成',
    procedure_des_5: '検証、ベータ版、本番環境へ移行',
    procedure_des_6: '検証、ベータ版、本番環境へ移行',
    content_mb:
      'オフショアとは一般的に、コスト削減を目的として、人件費や物価の安い海外企業に業務の一部を委託することです。',
  },
  recruitment: {
    titlemain: 'TCOM JAPAN 採用情報',
    title: 'TCOM JAPAN',
    title_dis: 'キャリア',
    recrui_position: '欠員',
    status_1: '最新',
    status_2: 'ほとんどのビュー',
    status_3: 'オファー',
  },
};
