export default {
  home: {
    title:
      'Provide offshore services with the best quality, the most optimal cost.',
    title_sub: 'Save and fit your budget',
    btn_readmore: 'ReadMore',
    news_hot: 'Feature News',
    service_provide: 'The Services We Provide',
    service_provide_sub:
      'we provide powerful, scalable and reliable software solutions to customers around the globe',
    reason: 'why you should choose TCOM JAPAN',
    reason_sub1: 'Reasonable cost save you 60%',
    reason_sub2: 'There is a Japanese quality manager',
    reason_sub3:
      '<p>team of highly qualified engineers,</br>professional process</p>',
    method_cooperation: 'METHODS OF COOPERATION TCOM JAPAN',
    method_cooperation_sub:
      'We offer flexible cooperation methods, suitable for most requirements',
    project_based_description:
      'Is an offshore form where contracts are signed for each project.',
    labor_description:
      'Customers build, manage and operate a separate team on demand, located at TCOM.',
    step_active: 'IMPLEMENTATION PROCESS',
    step_active_description:
      'The steps to exchange and work are simple, clear, and easy to cooperate',
    step1: 'Collect information',
    step1_des: 'Exchange ideas, consult, research and clarify requirements',
    step2: 'Design',
    step2_des: 'System architecture design, interface sketching, UI/UX design',
    step3: 'Programing',
    step3_des: 'Programming functions',
    step4: 'Testing',
    step4_des: 'Programming functions',
    step5: 'Implementation',
    step5_des: 'Deploy test run, run beta and run production',
    step6: 'Evaluate',
    step6_des: 'Quality evalution,performance',
    tech_language: 'TECHNOLOGY AND LANGUAGE',
    tech_language_description:
      'We continuously update and expand our technology capabilities, in order to better meet the quality of products for customers.',
    feature_project: 'Feature Project',
    feature_project_des:
      'Some outstanding projects that we have successfully implemented and satisfied customers',
    achievement_feature: 'Feature Achievement',
    achievement_feature_des:
      'As a reliable partner company for domestic and foreign companies, TCOM JAPAN will continue to pursue expansion of scale, improvement of team level, and strict quality control system.',
    project: 'Project',
    customer: 'Customer',
    engineer: 'Engineer',
    expert: 'Expert',
    job: 'recruitment',
    job_des: 'Career Development Opportunities at TCOM',
    image: 'Photo',
    office: 'TCOM OFFICE',
    news: 'News',
    news_des: 'Latest news, perspectives and tech events from TCOM JAPAN',
  },
  layout: {
    about: 'About',
    service: 'Service',
    solution: 'Solution',
    project: 'Project',
    job: 'Job',
    news: 'News',
    contact: 'Contact',
    home: 'Home',
    introduce: 'Introduce',
    offshore: 'Offshore service',
    terms_of_service: 'Terms of service',
    Privacy_Policy: 'Privacy policy',
    contact_description:
      'Send information for advice, quotation, or business cooperation contact. Please email us at any time. We are ready to listen and support you.',
    contact_submit: 'SEND CONTACT COOPERATION',
    footer_title: 'TCOM VIET NAM',
    footer_service: 'SERVICES',
  },
  service: {
    our_service_sub1: 'Our',
    our_service_sub2: 'Services',
    strength_sub1: 'Our',
    strength_sub2: 'Strengths',
    language_code: 'Language Code',
    tech: 'Technology',
    step_active: 'Step Action',
    step_active_description:
      'The steps to exchange and work are simple, clear, and easy to cooperate',
    field: 'FIELDS',
    field_description: 'Aspects in which we provide services',
  },
  project: {
    feature_project: 'Feature Projects',
    feature_project_description: '',
    project_sub1: 'We provide powerful software solutions,',
    project_sub2: 'scalable and reliable for customers globally.',
    field: 'fields',
    language: 'languages',
    no_result: 'No Result',
    back_home: 'Back To Home',
    load_more: 'LOAD MORE',
    tech: 'Technology',
    scale: 'Scale',
    platform: 'Platform',
    search: '',
  },
  news: {
    title: 'News',
  },
  about: {
    about_us: '',
    about_us_text1: '',
    about_us_text2: '',
    profile_title: '',
    tcom_japan: '',
    tcom_vietnam: '',

    title_sub1: `<h1>We are TCOM<br /> Multi-field technology company,<br /> Your trusted IT partner</h1>`,
    history_mount: 'History begin',
    history_description:
      ' <p>TCOM is a technology company, founded in 2012, by a team of IT engineers with more than 15 years of experience in product development as well as implementing technology projects with domestic and foreign partners.<br/> TCOM gathers people Talented, enthusiastic, passionate, creative, graduated from top universities in Vietnam</p>',
    mission: 'Mission',
    mission_description:
      '<p>Creating quality technology products and solutions, promoting the comprehensive digital transformation of society</p>',
    vision: 'Vision',
    vision_description:
      'Become the leading multi-field technology corporation in Vietnam',
    roadmap_development: 'Roadmap Development',
    staffplus: 'towards a scale of 100 employees',
    staff: 'Staff',
    establish: 'Establishment of a subsidiary company',
    establish_description:
      'TCOM Software, outsourcing service at home and abroad',
    rice_wallet: 'Debut Rice Wallet',
    rice_wallet_description: 'Decentralized Cryptocurrency Management Wallet',
    Tcom_Japan: 'TCOMJAPAN',
    japan_outsource: 'Outsourcing service in Japan',
    eyefire_description: 'artificial intelligence products and solutions',
    japan_singapo: 'Access to foreign markets Japan, Singapore',
    progress_end: 'Promote technology research, process improvement',
    japan_singapo_2018: 'Market Japan, Singapore',
    company_member: 'OUR MEMBERSHIP SYSTEM',
    achievements: 'ACHIEVEMENTS',
    achievement_description:
      'Always expanding the scale, improving the team level, strict quality control process, TCOM has been and is a reliable partner of domestic and foreign enterprises.',
    customer: 'Customer',
    experts: 'Experts',
    subTitle: `<p class='desc'>Tcom is constantly growing, we bring you solutions,<br/> technology that is convenient for life in the 4.0 era.</p>`,
    feedback: `Customer's feedback`,
    customer_uppercase: 'CUSTOMER',
    customer_uppercase_description: `<p className='desc'>We have customers from all over the world with dedication, responsibility,<br /> Creatively, we bring our partners the highest quality product services and satisfaction.</p>`,
    image: 'Image',
    office: 'TCOM Office',
    start: 'Start',
    established: 'established',
    research:
      'Technology research, development LiveStreaming products, WebRTC, AI, ML',
    menu_2021:
      'Improve the level of the team, upgrade the quality management process',
  },
  contact: {
    title: 'Contact Information',
    description: ` <p className='desc'>Send information for advice, quotation, or business cooperation contact.<br /> Please email us at any time.<br /> We are ready to listen and support you.</p>`,
    tcom_vn: 'TCOM Viet Nam',
    name: 'Name',
    phone: 'Mobile',
    tieu_de: 'Title',
    purpose: 'Purpose',
    content: 'Content',
    submit: 'SUBMIT',
    member: 'OUR MEMBERS',
  },
  solution: {
    feature_solution: 'Feature Solutions',
    feature_solution_description: `<p className='desc'>TCOM has been developing many comprehensive technology platforms and solutions,<br /> promote the process of digital transformation of businesses and organizations.</p>`,
    livestream_interactive: `<div className='name'>Livestream solution<br /> Interactive</div>`,
    livestream_sale: `<div className='name'>Livestream solution<br /> Sell</div>`,
    service_travel: ` <div className='name'>Service Solution<br /> Travel</div>`,
    digital: ` <div className='name'>Digital solutions<br /> VR 360</div>`,
    school_smart: ` <div className='name'>School Solutions<br /> Smart</div>`,
    camera: ` <div className='name'>Solution<br /> Cloud Camera</div>`,
    building_smart: `  <div className='name'>Solution<br /> Smart Building</div>`,
    education: `<div className='name'>Solution<br /> Online Education</div>`,
    telehealth: `<div className='name'>Solution<br /> Telehealth</div>`,

    solution_1: `<h5>Solution Livestream<br /> Interactive</h5>`,
    solution_1_description:
      'Developing applications that allow users to livestream, invite guests to live, add frames, stickers, edit images, and colors. Viewers can chat directly, join the livestream, give gifts, and give points.',
    solution_2: `<h5>Livestream solution<br /> Sell</h5>`,
    solution_2_description:
      'Developing an online-shopping system through livestream, livestream sellers introduce products from the warehouse, viewers interact, view products, and place orders directly.',
    solution_3: `<h5>Service Solution<br /> Travel</h5>`,
    solution_3_description:
      'Develop applications that allow users to livestream, invite guests to live, add frames, stickers, edit images, and colors. Viewers can chat directly, join the livestream, give gifts, and give points.',
    solution_4: `<h5>Digital solutions<br /> VR 360</h5>`,
    solution_4_description:
      'Developing an online tourism platform through 360 VR images, a virtual reality fair and exhibition platform',
    solution_5: `<h5>School Solutions<br /> Smart</h5>`,
    solution_5_description:
      'Developing a smart school solution, focusing on improving the quality of student management through identification, attendance, and supervision based on face recognition technology. At the same time ensure transparency',
    solution_6: `<h5>Solution<br /> Smart Building</h5>`,
    solution_6_description:
      'Developing smart building solutions, allowing automatic identification of residents through faces, to open doors, to use services. It also allows residents to remotely open doors for their guests.',
    solution_7: `<h5>Solution<br /> Cloud Camera</h5>`,
    solution_7_description:
      'Developing a centralized camera storage and management solution, a cloud platform, allowing viewing and analyzing images based on artificial intelligence technology.',
    solution_8: `<h5>Solution<br /> Online Education</h5>`,
    solution_8_description:
      'Developing learning systems, teaching online through video, through real-time interactive classroom.',
    solution_9: `<h5>Solution<br /> Telehealth</h5>`,
    solution_9_description:
      'Development of remote medical examination and consultation application on demand, allowing to match doctors and patients. Consulting through video call, instant message.',
  },
  text_button: {
    read_more: 'READ MORE',
    keep_in_touch: 'KEEP IN TOUCH',
  },
  off_shore: {
    title: '',
    title_dis: '',
    introduce: '',
    benefit: '',
    benefit_description: '',
    slogan1: '',
    slogan2: '',
    slogan_des_1: '',
    slogan_des_2: '',
    slogan_des_3: '',
    slogan_des_4: '',
    slogan_des_5: '',
    slogan_des_6: '',
    achievement: '',
    achievement1: '',
    company: '',
    service: '',
    reason: '',
    reason_1: '',
    reason_2: '',
    reason_3: '',
    method: '',
    method_1: '',
    method_2: '',
    method_des_1: '',
    method_des_2: '',
    pattern_1: '',
    pattern_2: '',
    pattern_des_1: '',
    pattern_des_2: '',
    service_1: '',
    service_2: '',
    service_3: '',
    service_4: '',
    service_5: '',
    service_6: '',
    service_7: '',
    service_8: '',
    service_9: '',
    procedure: '',
    procedure_des: '',
    procedure_1: '',
    procedure_2: '',
    procedure_3: '',
    procedure_4: '',
    procedure_5: '',
    procedure_6: '',
    procedure_des_1: '',
    procedure_des_2: '',
    procedure_des_3: '',
    procedure_des_4: '',
    procedure_des_5: '',
    procedure_des_6: '',
  },
  recruitment: {
    title: '',
    title_dis: '',
    recrui_position: '',
    status_1: '',
    status_2: '',
    status_3: '',
  },
};
