import Head from 'next/head';
export default function MetaSeo(props) {
    let { url, type, title, description, image, domain, card, keywords } = props;
    if (!url) {
        url = 'https://tcom-japan.com';
    }
    if (!type) {
        type = 'website';
    }
    if (!title) {
        title = 'TCOM JAPAN - ベトナムのトップオフショア開発会社';
    }
    if (!description) {
        description = 'TCOM JAPAN - ベトナムの大手オフショア開発会社 TCOM JAPANは、日本におけるベトナムの大手オフショア開発会社です。 優秀なエンジニアのチーム、ソフトウェア開発プロセス、専門的な品質管理により、最高の品質、最適なコストでオフショア サービスを提供します。';
    }
    if (!image) {
        image = `${process.env.NEXT_PUBLIC_SEO_URL}/assets/images/tcom.png`;
    }
    if (!domain) {
        domain = `${process.env.NEXT_PUBLIC_SEO_URL}/`;
    }
    if (!card) {
        card = 'summary_large_image';
    }
    if (!keywords) {
        keywords = 'Leading IT company in Vietnam , Outsourcing IT service in Vietnam, Offshore, Outsourcing, Artificial Intelligence, Blockchain, AR/VR, Livestreaming';
    }

    return (
        <>
            <Head>
                <title>{title}</title>
                <meta name='description' content={description} />
                <meta name='news_keywords' content={keywords} />
                <meta property='og:url' content={url} />
                <meta property='og:type' content={type} />
                <meta property='og:title' content={title} />
                <meta property='og:description' content={description} />
                <meta property='og:image' content={image} />
                <meta property='keywords' content={keywords} />
                <meta name='twitter:card' content={card} />
                <meta property='twitter:domain' content={domain} />
                <meta property='twitter:url' content={url} />
                <meta name='twitter:title' content={title} />
                <meta name='twitter:description' content={description} />
                <meta name='twitter:image' content={image} />
            </Head>
        </>
    );
}